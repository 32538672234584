'use client'

import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import { fetchBranches, fetchCommon } from '@/libs/actions/common.action'

//__DEFINE CONTEXT
interface DestinationData {
  common: any
  branches: any
}

interface DestinationContextType {
  data: DestinationData | null
}

//__CREATE CONTEXT
const DestinationContext = createContext<DestinationContextType | null>(null)

//__CREATE PROVIDER
export const useDestination = () => {
  const context = useContext(DestinationContext)
  if (!context) {
    throw new Error('useDestination must be used within a DestinationProvider')
  }
  return context
}

//__PROVIDER COMPONENT
export const DestinationProvider = ({ children }: { children: ReactNode }) => {
  //__STATE
  const [data, setData] = useState<DestinationData | null>(null)

  useEffect(() => {
    async function fetchData() {
      try {
        const [common, branches] = await Promise.all([fetchCommon('en'), fetchBranches('en')])
        setData({ common, branches })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [])

  return <DestinationContext.Provider value={{ data }}>{children}</DestinationContext.Provider>
}
